import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  parentPage: any;
  constructor(
    public params: NavParams
  ) { }

  ngOnInit() {}

  getUserPosition() {
    this.parentPage = this.params.get('ref');
    this.parentPage.getUserPosition();
    this.parentPage.closePopover();
  };

}
