import { Component,OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { NavController, ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { FarmaciasService } from './services/farmacias.service';
import {Router} from "@angular/router"


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  /*
  latitude = -31.6176881;
  longitude = -60.7151613;
  mapType = 'roadmap';
  zoom = 15;
  */

  public appPages = [
    {
      title: 'Mapa de Farmacias',
      url: '/mapa',
      icon: 'pin'
    },
    {
      title: 'Farmacias en tu zona',
      url: '',
      icon: 'list'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController, 
    private toastController: ToastController,
    public alertController: AlertController,
    private farmacerca: FarmaciasService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
