import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FarmaciasService {  
  private httpOptions: any;
  public user_token: string;
  public headers;
  public latitude_limit_norte;
  public latitude_limit_sur;
  public longitude_limit_este;
  public longitude_limit_oeste;
  public user_city;
  public user_provincia;
  public encrypted;
  public date;
  public farmacias_lista;
  public farmacias_lista_todas;
  public farmacias_lista_deturno;

  baseurl = "https://www.farmacerca.com/application"; 
  //baseurl = "http://dev.farmacerca.com/application";

  constructor(  private router: Router,
    private http: HttpClient) {
      this.httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      };
    }

  getFarmacias(): Observable<any> {
    let request = {
      longitud_este: this.longitude_limit_este,
      longitud_oeste: this.longitude_limit_oeste,
      latitud_norte: this.latitude_limit_norte,
      latitud_sur: this.latitude_limit_sur,
      date: this.date,
      getstring: this.encrypted,
      ciudad_name: this.user_city,
      provincia_name: this.user_provincia,
    };
    console.log(request);
    return this.http.post(this.baseurl + '/farmacias/all.php', JSON.stringify(request), this.httpOptions);
  }
}

