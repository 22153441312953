import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.scss'],
})
export class MetaComponent implements OnInit {
  public description:number;
  constructor() { }

  ngOnInit() {
    this.description = Math.floor(Math.random() * 100) + 1;

  }

}
