import { NgModule, ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { AppComponent } from './app.component';
import { MetaComponent  } from './meta/meta.component';
import { AppRoutingModule } from './app-routing.module';

import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';

import { AdsenseModule } from 'ng2-adsense';

import {NgxAdDfpModule} from 'ngx-ad-dfp';
import { PopoverComponent } from './popover/popover.component';

@NgModule({
  declarations: [AppComponent, MetaComponent, PopoverComponent],
  entryComponents: [PopoverComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgxAdDfpModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDRU-lI1YQ0Kd2Mk9uKsHRTpVoVfuu8INk',
      libraries: ['places']
    }),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-4559154611140863',
      adSlot: 2985897743,
      adFormat: 'rspv'
    }),
  ],
  exports: [],
  providers: [
    StatusBar,
    SplashScreen,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
